<template>
  <div class="wrap">
    <!-- order_number      订单号
order_type           订单类型（0-小程序   1-PC）
pay_time              支付时间
real_amount         实际充值金额
recharge_amount   购买套餐金额
remain_resume_point    剩余简历点
resume_end_time     简历点到期时间
resume_point   总购买简历点
resume_red_packet    此次订单消耗简历红包数
status   订单状态（0-未支付    1-已支付） -->
    <el-table
      :data="tableData"
      style="width: 100%"
      :row-class-name="rowClassName"
      empty-text="暂无记录"
      class="table"
    >
      <el-table-column prop="" label="状态" align="center">
        <template slot-scope="scope">
          <el-tag type="danger" size="mini" v-if="scope.row.status == 0"
            >未支付</el-tag
          >
          <el-tag type="success" size="mini" v-else>完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="pay_time"
        width="100"
        label="充值日期"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="" label="充值方式" min-width="120" align="center">
        <template slot-scope="scope">
          <span
            >{{ scope.row.recharge_amount }} 元 /
            {{ scope.row.resume_point }} 份</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="real_amount"
        label="实际充值金额"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="remain_resume_point"
        label="剩余简历份数"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="resume_red_packet"
        label="消耗简历红包"
        width="110"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="resume_end_time_str"
        label="简历下载到期日期"
        align="center"
        min-width="170"
      >
      </el-table-column>
      <el-table-column
        prop=""
        label="操作"
        align="center"
        min-width="180"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            v-if="scope.row.status == 0"
            @click="cancel(scope.row)"
            >取消订单</el-button
          >
          <el-button
            type="success"
            size="mini"
            v-if="scope.row.status == 0"
            @click="accomplish(scope.row)"
            >支 付</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="充值"
      :visible.sync="is_CZClose"
      width="30%"
      :before-close="chongZhiCloseY"
    >
      <div class="chongzhiC">
        <div>
          <vue-qr
            :text="payImg"
            :margin="0"
            colorDark="#111"
            colorLight="#fff"
            :logoSrc="payLogo"
            :logoScale="100"
            :size="200"
          ></vue-qr>
          <div v-if="is_payImgBg" class="is_payImgBg" @click="wx_native_order">
            二维码失效，点击刷新
          </div>
        </div>
        <p>微信扫码付款</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  seed_pay_record,
  reopen_order,
  restore_red_packet,
  native_order_query,
  seed_pay,
} from "@/api/me";
import vueQr from "vue-qr";
export default {
  components: { vueQr },
  data() {
    return {
      tableData: [],
      is_CZClose: false,
      payLogo: require("@/assets/images/main/logo.png"),
      payImg: "",
      is_payImgBg: false,
      nonce_str: "",
      out_trade_no: "",
      time_index: 0,
      time_tinner: null,
    };
  },
  methods: {
    rowClassName({ row, rowIndex }) {
      let newDate = Date.parse(new Date());

      let iDays = Math.floor(
        (row.resume_end_time - newDate / 1000) / 60 / 60 / 24
      ); //把相差的毫秒数转换为天数
      console.log(iDays);
      if (iDays <= 7 && row.status == 1) {
        return "error_row";
      }
    },

    accomplish(data) {
      this.is_CZClose = true;
      this.netPayIndex;
      reopen_order({
        out_trade_no: data.order_number,
      }).then((res) => {
        console.log("下单成功", res);
        if (!res.code) {
          this.payImg = res.data.code_url;
          this.out_trade_no = res.data.out_trade_no;
          this.nonce_str = res.data.nonce_str;
          this.time_tinner = setInterval(() => {
            this.time_index++;
            this.native_order_query();
          }, 3000);
          // this.$message.success("取消订单成功！");
        }
        if (res.code == 206002) {
          this.$message.warning(res.msg);
          this.is_CZClose = false;
          this.init();
        }
      });
    },
    cancel(data) {
      restore_red_packet({
        out_trade_no: data.order_number,
      }).then((res) => {
        console.log(res);
        if (!res.code) {
          this.$message.success("取消订单成功！");
          this.init();
        }
      });
    },
    wx_native_order() {},
    // 充值窗口关闭
    chongZhiCloseY() {
      if (this.time_tinner > 0) {
        this.$confirm("订单正在进行中, 确认关闭订单将取消订单?", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            console.log("确认取消");
            this.chongZhiClose();
            restore_red_packet({
              out_trade_no: this.out_trade_no,
            }).then((res) => {
              console.log(res);
              if (!res.code) {
                this.init();
              }
            });
          })
          .catch(() => {});
        return false;
      }
      this.is_CZClose = false;
    },
    chongZhiClose() {
      this.init();
      this.is_CZClose = false;
      if (this.time_tinner > 0) {
        clearInterval(this.time_tinner);
        this.time_tinner = null;
      }
    },
    //订单查询接口
    native_order_query() {
      console.log("轮询开始");
      native_order_query({
        out_trade_no: this.out_trade_no,
        nonce_str: this.nonce_str,
      }).then((res) => {
        console.log("查询订单", res);
        if (res.code == 0) {
          //支付成功
          clearInterval(this.time_tinner);
          this.time_tinner = null;
          this.seed_pay();
          // this.$message.success("支付成功！");
          this.chongZhiClose();
        } else if (res.code == 1 && this.time_index % 40 == 0) {
          clearInterval(this.time_tinner);
          this.time_tinner = null;
          this.is_payImgBg = true;
          this.$message.warning("支付二维码失效，请重新刷新！");
          this.is_payImg = true;
          restore_red_packet({
            out_trade_no: this.out_trade_no,
          }).then((res) => {
            console.log(res);
            if (!res.code) {
              this.$message.success("取消订单成功！");
              this.init();
            }
          });
        } else if (res.code == 2) {
          clearInterval(this.time_tinner);
          this.time_tinner = null;
          this.$message.error("订单已失效，请重新生成！");
          this.is_payImgBg = true;
        }
      });
    },
    //支付成功回调
    seed_pay() {
      seed_pay({
        out_trade_no: this.out_trade_no,
      }).then((res) => {
        console.log("充值成功接口");
        this.$message.success("支付成功！");
        this.init();
      });
    },
    init() {
      console.log("收支明细");
      seed_pay_record().then((res) => {
        if (!res.code) {
          console.log(res.data);
          this.tableData = res.data;
        }
      });
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    if (this.time_tinner > 0) {
      clearInterval(this.time_tinner);
      this.time_tinner = null;
    }
  },
};
</script>

<style scoped lang="scss">
.wrap {
  margin-top: 20px;
  @include padding;
  min-width: 1100px;
  box-shadow: #d8d7d7 0px 4px 9px 2px;
}
.chongzhiC {
  & > div {
    width: 200px;
    height: 200px;
    position: relative;
    margin: auto;
    .is_payImgBg {
      position: absolute;
      top: 0;
      width: 200px;
      line-height: 200px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      color: rgb(236, 236, 236);
      font-size: 16px;
      cursor: pointer;
    }
  }
  p {
    text-align: center;
    font-size: 18px;
    color: #aaa;
  }
}
.table /deep/ .error_row {
  background-color: #efcccc;
}
</style>